
* {
  margin: 0;
}

main {
  font-family: "Spartan", Helvetica, Arial, Lucida, sans-serif;
}

main.landing h2 {
  font-weight: 800;
  text-transform: uppercase;
  font-size: 32px;
  color: #525252 !important;
  line-height: 1.5em;
  text-align: center;
  margin-bottom: 55px;
}

main section {
  padding: 100px 30px;
}

main p {
  font-size: 16px;
  font-weight: 500;
  color: #525252 !important;
  line-height: 28px;
}

.information .bulletpoints {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.information .bulletpoints > div:first-child {
  margin-bottom: 90px;
}

.information .bulletpoints .point:not(:last-child) {
  margin-bottom: 90px;
}

.information .bulletpoints .point {
  flex-basis: 100%;
  flex-shrink: 0;
}

.accountTypes {
  background-color: #f6f5f5 !important;
}

.accountTypes > p {
  text-align: center;
  margin-bottom: 50px;
  color: #666 !important;
}

.accountTypes > div {
  display: flex;
  flex-direction: column;
}

.accountTypes > div > div:first-child {
  margin-bottom: 30px;
}

.accountTypes > div > div span {
  display: block;
  font-size: 18px;
  font-weight: 800;
  line-height: 18px;
  color: #525252 !important;
  text-align: center;
  margin-bottom: 20px;
}

.accountTypes > div > div img {
  width: 100%;
  box-shadow: 0px 0px 12px 0px rgb(0 0 0 / 30%);
}

section.prices {
  padding: 54px 30px;
  background-size: 200% 200%;
  background-image: url('../../img/Verlauf.png');
  background-attachment: fixed;
  animation: diagonal alternate 30s infinite;
}

section.prices h2 {
  color: #fff !important;
}

.priceCards {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.priceCards .pricecard {
  flex-basis: 100%;
}

.pricecard:last-child .price div:last-child {
  margin-top: 30px;
  margin-left: 0;
}

@media (min-width: 470px) {
  .pricecard:last-child .price {
    flex-direction: row !important;
  }

  .pricecard:last-child .price div:last-child {
    margin-top: 0;
    margin-left: 30px;
  }
}

@media (min-width: 768px) {
  .accountTypes {
    padding: 100px 10%;
  }

  .priceCards .pricecard {
    flex-basis: 31%;
  }
}

@media (min-width: 981px) {
  .information {
    max-width: 1200px;
    margin: auto;
  }

  .information .bulletpoints {
    flex-direction: row;
    justify-content: space-between;
  }

  .information .bulletpoints > div {
    flex-basis: 48%;
  }

  .accountTypes > div {
    flex-direction: row;
    justify-content: space-between;
  }

  .accountTypes > div > div:first-child {
    flex-basis: 28%;
  }

  .accountTypes > div > div:last-child {
    flex-basis: 68%;
  }

  .priceCards {
    flex-direction: row;
    max-width: 1200px;
    margin: auto;
    align-items: flex-start;
  }

  .pricecard:last-child .price {
    flex-direction: column !important;
  }

  .pricecard:last-child .price div:last-child {
    margin-top: 30px;
    margin-left: 0px;
  }
}

@media (min-width: 1260px) {
  .pricecard:last-child .price {
    flex-direction: row !important;
  }

  .pricecard:last-child .price div:last-child {
    margin-top: 0;
    margin-left: 30px;
  }
}
