.pricecardElement {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50px;
    text-align: center;
    padding: 15px 10px;
    font-size: 14px;
    color: #525252 !important;
    line-height: 25px;
    font-weight: 500;
  }
  
  .pricecardElement:not(:last-child) {
    border-bottom: lightgrey 1px solid;
  }
  
  .pricecardElement .caption {
    position: absolute;
    top: -1px;
    right: -9px;
    padding: 4px 10px;
    border-radius: 50%;
  }
  
  .pricecardElement .caption::after {
    width: 300px;
    white-space: normal !important;
  }
  