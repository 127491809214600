.pricecard:not(:last-child) {
    margin-bottom: 30px;
  }
  
  .pricecard {
    text-align: center;
    background: #fff;
    border-radius: 7px;
    padding: 10px;
  }
  
  .pricecard .heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 80px;
    text-align: center;
    padding: 15px 10px;
    border-bottom: lightgrey 1px solid;
    white-space: pre-wrap;
    font-size: 14px;
    font-weight: 700;
  }
  
  .pricecard .heading h3 {
    font-weight: 800;
    font-size: 26px;
    text-align: center;
    padding-bottom: 7px;
  }
  
  .pricecard .price {
    font-size: 26px;
    font-weight: 800;
    line-height: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .pricecard .price .value {
    margin-top: 10px;
  }
  
  .pricecard .price span {
    display: block;
    font-size: 12px;
    font-weight: 500;
    margin-top: 10px;
  }
  