.db_header {
    width: 100%;
    background-color: white;
    background-size: 200% 200%;
    background-image: url('../../img/Verlauf.png');
    background-attachment: fixed;
    animation: diagonal alternate 30s infinite;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 25px;
    color: white;
    position: relative;
    padding: 100px 0 0 0;
}

.db_headline {
    font-size: 35px;
    font-weight: 800;
}

@keyframes diagonal {
    0% {background-position: 0% 50%}
    100% {background-position: 100% 50%}
}

.db_lineBreak {
    margin-top: -150px;
    height: 150px;
    background-image: url('../../img/TrennerObenIventi.svg');
    background-size: cover;
    background-position: top;
    position: relative;
}

.db_lineBreakGrey {
    margin-top: -150px;
    height: 150px;
    background-image: url('../../img/TrennerObenGrau.svg');
    background-size: cover;
    background-position: top;
}

.db_button {
    width: 150px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: lightgray;
    margin: 10px;
    text-align: center;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    font-weight: 800;
    cursor: pointer;
    text-decoration: none;
}

.db_button.active {
    background-color: white;
    background-size: 200% 200%;
    background-image: url('../../img/Verlauf.png');
    background-attachment: fixed;
    animation: diagonal alternate 30s infinite;
}

.db_button:hover {
    background-color: white;
    background-size: 200% 200%;
    background-image: url('../../img/Verlauf.png');
    background-attachment: fixed;
    animation: diagonal alternate 30s infinite;
}

.db_navigation {
    display: flex;
    max-width: 1200px;
    justify-content: center;
    width: 90%;
    margin: auto;
    flex-wrap: wrap;
}

.db_cartItem {
    width: 50px;
    height: 50px;
}

.db_content {
    background-color: white;
    padding-bottom: 30px;
    padding-top: 10px;
}

.db_lineBreakBottom {
    margin-top: -50px;
    height: 150px;
    background-image: url('../../img/lineBreakButton.svg');
    background-size: cover;
    background-position: bottom;
}

.db_lineBreakBottomWhite {
    margin-top: -20px;
    height: 150px;
    background-image: url('../../img/lineBreakButtonWhite.svg');
    background-size: cover;
    background-position: bottom;
    position: relative;
    z-index: 2;
}