@media only screen and (max-width: 980px) {
	.sum_summary {
		flex-direction: column;
		padding: 15px;
	}
	.sum_columnCoupon, .sum_columnPriceSummary {
		padding: 0!important;
		margin-top: 35px;
	}
}

.cartContainer {
	padding: 10px;
}

@media only screen and (max-width: 900px) {
	.db_headline {
		font-size: 32px!important;
		text-align: center;
	}
	.db_headlineUnder {
		font-size: 17px!important;
	}

	.galleryAndContent {
		flex-direction: column;
	}

	.firstColumn {
		order: 2
	}

	.secondColumn {
		order: 1
	}
}

.alarmText {
	max-width: 80%!important;
}

@media only screen and (max-width: 750px) {
	.db_headline {
		font-size: 32px!important;
		text-align: center;
	}
	.db_headlineUnder {
		font-size: 17px!important;
	}

	.selectContainer {
		flex-direction: column;
	}

	.CustomSelect {
		width: 100%!important;
	}
	.dp_inputFields {
		flex-direction: column;
	}
	.buttonContainer {
		flex-direction: column;
	}
}

@media only screen and (max-width: 450px) {
	.db_headline {
		max-width: 90%;
	}
	.db_header {
		padding-bottom: 50px!important;
	}
	.db_headlineUnder {
		font-size: 17px!important;
		padding-bottom: 50px;
	}
	.information h2, .accountTypes h2, .prices h2 {
		font-size: 25px!important;
	}
}

@media only screen and (max-width: 1150px) {
	.ap_adsContainer {
		flex-direction: column;
	}
}

@media only screen and (max-width: 500px) {
	.SmallAd {
		flex-direction: column;
	}
	.sa_image img {
		width: 100%;
		border-radius: 5px 5px 0 0!important;
	}
	.sa_image {
		width: 100%;
	}
	.sa_info {
		margin: unset!important;
		padding: 10px;
	}
}

@media only screen and (max-width: 580px) {
	.sum_cartItem {
		flex-wrap: wrap;
	}
}