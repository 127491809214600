.ProviderGallery .hiddenInput {
    display: none;
}

.ProviderGallery .imageContainer {
    display: flex;
    flex-direction: row;
}

.ProviderGallery .imageContainer img {
    width: 50px;
    height: 50px;
    object-fit: cover;
}

.ProviderGallery .uploadContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;
    background-color: white;
    box-shadow: 1px 1px 5px #0000001a;
    border-radius: 5px;
}

.ProviderGallery .headlineProv {
    text-align: center;
    font-weight: 800;
    padding: 20px 20px 20px 20px;
    margin: 0;
}

.ProviderGallery .uploadInnderContainer {
    text-align: center;
}

.ProviderGallery .text {
    font-size: 12px;
    padding: 0 20px 10px 20px;
    line-height: 20px;
}

.ProviderGallery .imagePlaceholderContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
}

.ProviderGallery .imagePlaceholderContainer img {
    width: 50px;
    height: 50px;
    margin: 0px 2px 5px 2px;
    background-color: white;
    box-shadow: 1px 1px 5px #0000001a;
    display: block;
}

.react-thumbnail-generator img {
    width: 50px;
    height: 50px;
    display: block;
    object-fit: contain;
}

.react-thumbnail-generator canvas, .react-thumbnail-generator video {
    display: none;
}