.PopUp {
    width: 100%;
    height: 100vh;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.212);
    z-index: 100000;
    top: 0px;
    left: 0px
}

.PopUp .innerPopUp {
    background-color: white;
    width: 400px;
    max-width: 90%;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
}

.PopUp .messagePopUp {
    font-weight: 500;
    font-size: 14px;
    padding: 20px 20px 20px 20px;
    text-align: center;
    line-height: 20px;
}

.PopUp .headlinePopUp {
    font-weight:800;
    text-transform: uppercase;
    padding-top: 20px;
}
