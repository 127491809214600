.LandingPage .header {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    border-radius: 0 0 30px 30px;
}

.LandingPage .logo {
    position: absolute;
    width: 120px;
    top: 20px;
    left: 20px;
}

.LandingPage h1 {
    font-size: 45px;
    text-transform: uppercase;
    font-weight: 800;
    text-shadow: 1px 1px 5px #00000052;
}

.LandingPage p {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    text-shadow: 1px 1px 5px #00000052;
}

.LandingPage .backgroundKeyframe {
    background-size: 200% 200%;
    background-image: url('../../img/Verlauf.png');
    background-attachment: fixed;
    animation: diagonal alternate 30s infinite;
}

.LandingPage .sectionBreak {
    height: 50px;
    position: relative;
}

.LandingPage .sectionBreakInner {
    background-color: white;
    height: 30px;
    width: 50%;
    position: absolute;
    top: 0px;
}

.LandingPage .sectionBreakInner:nth-child(2) {
    right: 0;
    border-radius: 200px 0 0 0;
}

.LandingPage .sectionBreakInner:nth-child(1) {
    left: 0;
    border-radius: 0 200px 0 0;
}

.LandingPage .sectionBreakBlender {
    background-color: white;
    position: absolute;
    height: 20px;
    top: 30px;
    width: 100%;
}

.LandingPage .button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 80px 60px 80px;
    border-radius: 10px;
    color: white;
    margin-top: 30px;
    text-shadow: 1px 1px 5px #00000052;
}

.LandingPage img {
    width: 60px;
}

.LandingPage .CTA {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #525252;
    margin-top: 30px;
    margin-bottom: 60px;
}

.LandingPage .CTA h2 {
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 800;
}

.LandingPage .CTALabel {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    margin-top: 15px;
    font-size: 20px;
    font-weight: 800;
    text-transform: uppercase;
}



@keyframes diagonal {
    0% {background-position: 0% 50%}
    100% {background-position: 100% 50%}
}