.Header .header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    border-radius: 0 0 30px 30px;
}

.logo {
    position: absolute;
    width: 120px;
    top: 20px;
    left: 20px;
    z-index: 100000;
}

.Header h1 {
    font-size: 45px;
    font-weight: 800;
    text-shadow: 1px 1px 5px #00000052;
}

.Header p {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    text-shadow: 1px 1px 5px #00000052;
}

.Header .backgroundKeyframe {
    background-size: 200% 200%;
    background-image: url('../../img/Verlauf.png');
    background-attachment: fixed;
    animation: diagonal alternate 30s infinite;
}

.Header .sectionBreak {
    height: 50px;
    position: relative;
}

.Header .sectionBreakInner {
    background-color: white;
    height: 30px;
    width: 50%;
    position: absolute;
    top: 0px;
}

.Header .sectionBreakInner:nth-child(2) {
    right: 0;
    border-radius: 200px 0 0 0;
}

.Header .sectionBreakInner:nth-child(1) {
    left: 0;
    border-radius: 0 200px 0 0;
}

.Header .sectionBreakBlender {
    background-color: white;
    position: absolute;
    height: 20px;
    top: 30px;
    width: 100%;
}