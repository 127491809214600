.Summary {
    width: 100%;
    background-size: 200% 200%;
    background-image: url('../../img/Verlauf.png');
    background-attachment: fixed;
    animation: diagonal alternate 30s infinite;
}

.cartContainer {
    max-width: 1000px;
    margin: auto;
}

.Summary h1 {
    font-size: 25px;
    color: white;
    text-transform: uppercase;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 20px;
}

.sum_cartItem {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 20px;
    box-shadow: 1px 1px 5px #0000001a;
    border-radius: 10px;
    margin-top: 20px;
}

.sum_cartItem img {
    width: 100px;
    height: 100px;
    display: block;
    object-fit: cover;
    padding-right: 30px;
}

.ca_thumbnail {
    width: 100px;
    height: 100px;
    display: block;
    object-fit: cover;
    padding-right: 30px;
}

.sum_columnPrice {
    margin-left: auto;
}

.sum_price {
    float: right;
    padding-right: 10px;
    font-size: 20px;
}

.sum_headline {
    font-weight: 800;
    padding-bottom: 10px;
}

.sum_text {
    font-size: 14px;
    line-height: 20px;
}

.sum_edit {
    padding-top: 10px;
    text-decoration: underline;
    cursor: pointer;
}

.sum_headlineSmall {
    font-weight: 800;
    padding-bottom: 10px;
    font-size: 14px;
}

.sum_columnPayment {
    display: flex;
    flex-direction: column;
    flex-basis: 30%;
}

.sum_coupon_textField {
    width: 100%;
    padding: 10px;
    outline: none;
    border: unset;
    box-shadow: 1px 1px 5px #0000001a;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 14px;
    line-height: 20px;
}

.sum_sumbmitCoupon {
    height: 50px;
    width: 70px;
    min-width: 70px;
    background-color: #f9b300;
    border-radius: 5px;
    margin-left: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sum_sumbmitCoupon img {
    height: 30px;
    padding: 15px;
}

.sum_couponHolder {
    display: flex;
}

.sum_summary {
	display: flex;
	width: 100%;
}

.sum_columnPriceSummary {
    flex-basis: 30%;
    padding-left: 30px;
    font-size: 14px;
}

.sum_columnCoupon {
    flex-basis: 40%;
    padding-left: 30px;
}

.sum_priceList {
    display: flex;
    justify-content: space-between;
    padding: 3px 0 3px 0;
}

.sum_columnPriceSummary hr {
    margin: 5px 0 5px 0;
}

.submitFlex {
    background-color: white;
    display: flex;
    justify-content: center;
    padding: 30px 0 30px 0;
}
